import React from 'react';
import clsx from 'clsx';

import SEO from '../components/seo';
import LegalNav from '../components/legal-nav';

import styles from '../styles/legal.module.scss';
import buttonStyles from '../styles/buttons.module.scss';

const HelpButton = () => (
    <button
        type='button'
        className={clsx(buttonStyles.link, buttonStyles.secondary, buttonStyles.bold)}
        onClick={() => window.zE('webWidget', 'open')}
    >
        contact us
    </button >
);

const ShippingAndReturnsPage = () => (
    <>
        <SEO
            title='Shipping and Returns'
            path='/shipping-and-returns/'
            description='View our shipping and return policy.'
        />
        <div className={styles.main}>
            <div className={styles.content}>
                <LegalNav />
                <section className={styles.doc}>
                    <h1>Shipping and Returns</h1>
                    <p><strong>Last updated September 15th, 2019</strong></p>
                    <h5>Shipping</h5>
                    <h6>For Subscription Orders:</h6>
                    <p>Shipping for all subscription orders is free.</p>
                    <p>Subscriptions ship at the beginning of every month.</p>
                    <p>Your subscription must be active before the <mark>1st day of the month at 12AM PST</mark> to ship that month.</p>
                    <p>For example, if you subscribe on <mark>January 31st</mark> your order will ship by <mark>February 4th</mark>.</p>
                    <p>If you subscribe on <mark>February 1st</mark> your order will ship by <mark>March 4th</mark>.</p>
                    <p>If you have any questions about your order, please <HelpButton /> and we will get back to you within 24 hours.</p>
                    <h6>For Store Orders:</h6>
                    <p>Shipping to the US for store orders for orders over $50 is free.</p>
                    <p>You have the option to choose expedited shipping at additional cost.</p>
                    <p>Store orders ship within 1-3 days of placing your order.</p>
                    <p>If you have any questions about your order, please <HelpButton /> and we will get back to you within 24 hours.</p>
                    <h5>Returns</h5>
                    <h6>For Subscription Orders:</h6>
                    <p>To initiate a return, please <HelpButton /> and we will get back to you within 24 hours.</p>
                    <p>You can return your subscription order within 30 days of receipt for a full refund.</p>
                    <p><mark>Please keep all your packaging in case you want to return your order.</mark></p>
                    <p>We will email you a label that you can print and tape to your packaging and mail back to us via USPS.</p>
                    <p>Once we receive and verify your return we will refund you the full amount of your order for that month.</p>
                    <h6>For Store Orders:</h6>
                    <p>To initiate a return, please <HelpButton /> and we will get back to you within 24 hours.</p>
                    <p>You can return your store order within 30 days of receipt for a full refund.</p>
                    <p><mark>Please keep all your packaging in case you want to return your order.</mark></p>
                    <p>Partial returns for only certain items from your order are allowed.</p>
                    <p>We will email you a label that you can print and tape to your packaging and mail back to us via USPS.</p>
                    <p>Once we receive and verify your return we will refund you the total amount of your order.</p>
                    <p>For partial returns we will refund you the total amount of the items returned.</p>
                </section>
            </div>
        </div>
    </>
);

export default ShippingAndReturnsPage;